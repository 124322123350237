.Language-Korean .Answer-Grace2-Q1, 
.Language-English .Answer-Grace2-Q1 {
    max-width: 217px;
}

.Language-Korean .Answer-Grace2-Q2 {
    max-width: 255px;
}

.Language-English .Answer-Grace2-Q2 {
    max-width: 190px;
}

@media only screen and (min-width: 960px) {
    /* Grace2 : Question 1 */
    .Language-Korean .Law-Grace2-Q1 {
        max-width: 327px;
    }

    /* Grace2 : (Col 1:14) */
    .Law-Grace2-Col114 {
        max-width: unset;
    }

    /* Grace2 : (Col 2:13) */
    .Locale-ko-RR .Law-Grace2-Col213 {
        max-width: 500px;
    }

    .Locale-ko-NT .Law-Grace2-Col213 {
        max-width: 545px;
    }

    .Locale-en .Law-Grace2-Col213 {
        max-width: 580px;
    }
}

@media only screen and (min-width: 1280px) {
    /* Grace2 : Question 1 */
    .Language-Korean .Law-Grace2-Q1 {
        max-width: 388px;
    }

    /* Grace2 : (Col 2:13) */
    .Locale-ko-RR .Law-Grace2-Col213 {
        max-width: 625px;
    }

    .Locale-ko-NT .Law-Grace2-Col213 {
        max-width: 680px;
    }

    .Locale-en .Law-Grace2-Col213 {
        max-width: 725px;
    }
}