.Language-Korean .Law-Wrapup3-Title, 
.Language-Korean .Law-Wrapup3-Content, 
.Language-English .Law-Wrapup3-Content {
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 600px) {
    .Language-Korean .Law-Wrapup3-Title {
        max-width: 477px;
    }
    
    .Language-Korean .Law-Wrapup3-Content {
        max-width: 515px;
    }
    
    .Language-English .Law-Wrapup3-Content {
        max-width: 702px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-Korean .Law-Wrapup3-Title {
        max-width: unset;
    }
    
    .Language-Korean .Law-Wrapup3-Content {
        max-width: 607px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-Wrapup3-Content {
        max-width: 759px;
    }
    
    .Language-English .Law-Wrapup3-Content {
        max-width: unset;
    }
}