.Answer.Answer-Confirm-third-question2 {
    max-width: 85px;
}

@media only screen and (min-width: 600px) {
    .Language-Korean .Law-ConfirmThird-Q2-Title {
        max-width: 515px;
    }

    .Language-English .Law-ConfirmThird-Q2-Title {
        max-width: 535px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-ConfirmThird-Q2-Title {
        max-width: 640px;
    }

    .Language-English .Law-ConfirmThird-Q2-Title {
        max-width: 670px;
    }
}