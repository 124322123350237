@media only screen and (min-width: 960px) {
    .Language-Korean .Law-Wrapup2 {
        max-width: 680px;
    }

    .Language-English .Law-Wrapup2 {
        max-width: 535px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-Wrapup2 {
        max-width: 850px;
    }

    .Language-English .Law-Wrapup2 {
        max-width: 670px;
    }
}