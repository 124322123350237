@media only screen and (min-width: 960px) {
    /* Grace1 : (Rev 3:20) */
    .Locale-ko-RR .Law-Grace1-Rev320 {
        max-width: 488px;
    }

    .Locale-ko-NT .Law-Grace1-Rev320 {
        max-width: 538px;
    }

    .Locale-en .Law-Grace1-Rev320 {
        max-width: 502px;
    }
    
    /* Grace1 : (Col 1:27) */
    .Locale-ko-RR .Law-Grace1-Col127 {
        max-width: 540px;
    }

    .Locale-ko-NT .Law-Grace1-Col127 {
        max-width: 585px;
    }

    .Locale-en .Law-Grace1-Col127 {
        max-width: 525px;
    }
}

@media only screen and (min-width: 1280px) {
    /* Grace1 : (Rev 3:20) */
    .Locale-ko-RR .Law-Grace1-Rev320 {
        max-width: 601px;
    }

    .Locale-ko-NT .Law-Grace1-Rev320 {
        max-width: 672px;
    }

    .Locale-en .Law-Grace1-Rev320 {
        max-width: 627px;
    }

    /* Grace1 : (Col 1:27) */
    .Locale-ko-RR .Law-Grace1-Col127 {
        max-width: 675px;
    }

    .Locale-ko-NT .Law-Grace1-Col127 {
        max-width: 730px;
    }

    .Locale-en .Law-Grace1-Col127 {
        max-width: 655px;
    }
}