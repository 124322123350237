.Language-English .Answer.Answer-Confirm-second-question4 {
    max-width: 165px;
}

.Language-Korean .Answer.Answer-Confirm-second-question4 {
    max-width: 100px;
}

@media only screen and (min-width: 600px) {
    .Law-ConfirmSecond-Q4-Title {
        max-width: 445px;
    }
}

@media only screen and (min-width: 1280px) {
    .Law-ConfirmSecond-Q4-Title {
        max-width: 556px;
    }
}