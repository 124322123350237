.LawThree-Four-flex {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.LawThree-Four-flex .LawThree-Four-DiagramBox {
    font-size: 300px;
    width: 300px;
    height: 300px;
    margin-bottom: 30px;
}

.LawThree-Four-flex .LawThree-Four-DiagramBox > img {
    font-size: inherit;
    width: inherit;
    height: inherit;
}

@media only screen and (min-width: 960px) {
    .LawThree-Four-flex {
        flex-direction: row;
    }

    .LawThree-Four-flex .LawThree-Four-DiagramBox {
        font-size: 260px;
        width: 260px;
        height: 260px;
        margin-bottom: 0;
        margin-right: 30px;
    }

    .LawThree-Four.Law-LongSentence {
        max-width: 665px;
    }
}

@media only screen and (min-width: 1280px) {
    .LawThree-Four-flex .LawThree-Four-DiagramBox {
        font-size: 300px;
        width: 300px;
        height: 300px;
    }

    .LawThree-Four.Law-LongSentence {
        max-width: 800px;
    }
}