.PageTitle.LawTwo-PageTitle .PageTitle-Wrapper {
    max-width: 245px;
} 

@media only screen and (min-width: 600px) {
    .PageTitle.LawTwo-PageTitle .PageTitle-Wrapper {
        max-width: 494px;
    }
}

@media only screen and (min-width: 960px) {
    .PageTitle.LawTwo-PageTitle .PageTitle-Wrapper {
        max-width: 590px;
    }
}

@media only screen and (min-width: 1280px) {
    .PageTitle.LawTwo-PageTitle .PageTitle-Wrapper {
        max-width: 735px;
    }
}