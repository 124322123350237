@media only screen and (min-width: 600px) {
    .Grace-Title-line2 {
        max-width: 410px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-Korean .Grace-Title-line2 {
        max-width: 535px;
    }

    .Language-English .Grace-Title-line2 {
        max-width: 545px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Grace-Title-line2 {
        max-width: 670px;
    }

    .Language-English .Grace-Title-line2 {
        max-width: 680px;
    }
}