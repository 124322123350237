.Confirm1-QWords {
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 960px) {
    .Locale-ko-RR .Confirm1-QWords {
        max-width: 650px;
    }

    .Locale-ko-NT .Confirm1-QWords {
        max-width: 685px;
    }

    .Locale-en .Confirm1-QWords {
        max-width: 490px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .Confirm1-QWords {
        max-width: 810px;
    }

    .Locale-ko-NT .Confirm1-QWords {
        max-width: 860px;
    }

    .Locale-en .Confirm1-QWords {
        max-width: 610px;
    }
}