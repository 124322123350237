@media only screen and (min-width: 960px) {
    .Locale-ko-RR .LawTwo-One.Law-LongSentence {
        max-width: 490px;
    }

    .Locale-ko-NT .LawTwo-One.Law-LongSentence {
        max-width: 550px;
    }

    .Locale-en .LawTwo-One.Law-LongSentence {
        max-width: 545px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .LawTwo-One.Law-LongSentence {
        max-width: 610px;
    }

    .Locale-ko-NT .LawTwo-One.Law-LongSentence {
        max-width: 685px;
    }
    
    .Locale-en .LawTwo-One.Law-LongSentence {
        max-width: 680px;
    }
}