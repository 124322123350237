@media only screen and (min-width: 960px) {
    .Language-Korean .Prayer-One {
        max-width: 585px;
    }

    .Language-English .Prayer-One {
        max-width: 525px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Prayer-One {
        max-width: 730px;
    }

    .Language-English .Prayer-One {
        max-width: 650px;
    }
}