@media only screen and (min-width: 600px) {
    .Law-ConfirmMain {
        margin: 0 -1px;
    }
}

@media only screen and (min-width: 960px) {
    .Law-ConfirmMain {
        margin: 0;
    }
}