.MuiContainer-root.PageContainer {
    padding: 101px 45px;
    overflow: auto;
    min-height: 100vh;
    display: flex;
}

.PageContainer .PageContainer-Wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 600px) {
    .MuiContainer-root.PageContainer {
        padding-left: 45px;
        padding-right: 45px;
    }
}

@media only screen and (min-width: 960px) {
    .MuiContainer-root.PageContainer {
        max-width: 800px;
    }
} 

@media only screen and (min-width: 1280px) {
    .MuiContainer-root.PageContainer {
        max-width: 920px;
        padding-left: 24px;
        padding-right: 24px;
    }
}