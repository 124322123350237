.PageText-Title {
    font-size: 28px;
}

.PageText-Content {
    font-size: 20px;
}

@media only screen and (min-width: 600px) {
    .PageText-Title {
        font-size: 30px;
        line-height: 42px;
    }

    .PageText-Content {
        font-size: 24px;
        line-height: 42px;
    }
}

@media only screen and (min-width: 1280px) {
    .PageText-Title {
        font-size: 40px;
        line-height: 55px;
    }

    .PageText-Content {
        font-size: 30px;
        line-height: 55px;
    }
}