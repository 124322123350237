@media only screen and (min-width: 600px) {
    .Language-Korean .Law-GracePrepMain1 {
        max-width: 510px;
    }
    
    .Language-English .Law-GracePrepMain1 {
        max-width: 430px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-English .Law-GracePrepMain1 {
        max-width: unset;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-GracePrepMain1 {
        max-width: 639px;
    }
}