@media only screen and (min-width: 600px) {
    .PageText.LawThree-Wrapup {
        max-width: 486px;
    }
}

@media only screen and (min-width: 960px) {
    .PageText.LawThree-Wrapup {
        max-width: unset;
    }
}