@media only screen and (min-width: 960px) {
    /* Grace5 : (John 10:10), (2 Cor 5:17) */
    .Locale-ko-RR .Law-Grace5-John1010 {
        max-width: 640px;
    }

    .Locale-ko-NT .Law-Grace5-John1010 {
        max-width: 555px;
    }

    .Locale-en .Law-Grace5-John1010,
    .Locale-en .Law-Grace5-IICor517,
    .Locale-ko-NT .Law-Grace5-IICor517 {
        max-width: 595px;
    }

    .Locale-ko-RR .Law-Grace5-IICor517 {
        max-width: 570px;
    }
}

@media only screen and (min-width: 1280px) {
    /* Grace5 : (John 10:10), (2 Cor 5:17) */
    .Locale-ko-RR .Law-Grace5-John1010 {
        max-width: 800px;
    }

    .Locale-ko-NT .Law-Grace5-John1010 {
        max-width: 700px;
    }

    .Locale-en .Law-Grace5-John1010,
    .Locale-en .Law-Grace5-IICor517,
    .Locale-ko-NT .Law-Grace5-IICor517 {
        max-width: 745px;
    }

    .Locale-ko-RR .Law-Grace5-IICor517 {
        max-width: 710px;
    }
}
