.BottomNavigation-Background {
    position: fixed;
    height: 91px;
    background-color: white;
    width: 100%;
    left: 0;
    bottom: 0;
}

.BottomNavigation-Previous, .BottomNavigation-Next {
    position: fixed;
    bottom: 30px;
    text-decoration: none;
    color: black;
    z-index: 1100;
}

.BottomNavigation-Previous {
    left: 30px;
}

.BottomNavigation-Next {
    right: 30px;
}

.BottomNavigation-Link-Wrapper {
    display: flex;
    align-items: center;
}

.BottomNavigation-Link-Wrapper .Previous-Arrow {
    transform: rotate(180deg);
    margin-right: 10px;
    width: 1.5rem;
    height: 1.5rem;
}

.BottomNavigation-Link-Wrapper .Next-Arrow {
    margin-left: 10px;
    width: 1.5rem;
    height: 1.5rem;
}