.Language-Korean .FourLawsMain-Wrapup, 
.Language-English .FourLawsMain-Wrapup {
    margin: auto;
    text-align: center;
    font-weight: bold;
    max-width: 350px;
}

@media only screen and (min-width: 600px) {
    .Language-Korean .FourLawsMain-Wrapup {
        max-width: 446px;
    }

    .Language-English .FourLawsMain-Wrapup {
        max-width: 468px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .FourLawsMain-Wrapup, 
    .Language-English .FourLawsMain-Wrapup {
        max-width: 667px;
    }
}