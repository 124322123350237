@media only screen and (min-width: 960px) {
    .Language-Korean .LawFour-Greet-One {
        max-width: 670px;
    }

    .Language-English .LawFour-Greet-One {
        max-width: 650px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .LawFour-Greet-One {
        max-width: 820px;
    }

    .Language-English .LawFour-Greet-One {
        max-width: 795px;
    }
}