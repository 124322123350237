@media only screen and (min-width: 960px) {
    .Locale-ko-RR .Law-Suggest1-John157 {
        max-width: 495px;
    }

    .Locale-ko-NT .Law-Suggest1-John157 {
        max-width: 605px;
    }

    .Locale-en .Law-Suggest1-John157 {
        max-width: 522px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .Law-Suggest1-John157 {
        max-width: 620px;
    }

    .Locale-ko-NT .Law-Suggest1-John157 {
        max-width: 755px;
    }
    
    .Locale-en .Law-Suggest1-John157 {
        max-width: 652px;
    }
}