.PageTitle.LawThree-PageTitle .PageTitle-Wrapper {
    max-width: 245px;
} 

@media only screen and (min-width: 600px) {
    .PageTitle.LawThree-PageTitle .PageTitle-Wrapper {
        max-width: 479px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-Korean .PageTitle.LawThree-PageTitle .PageTitle-Wrapper {
        max-width: 730px;
    }

    .Language-English .PageTitle.LawThree-PageTitle .PageTitle-Wrapper {
        max-width: 620px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .PageTitle.LawThree-PageTitle .PageTitle-Wrapper {
        max-width: 910px;
    }

    .Language-English .PageTitle.LawThree-PageTitle .PageTitle-Wrapper {
        max-width: 770px;
    }
}