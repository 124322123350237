@media only screen and (min-width: 960px) {
    .Locale-ko-RR .LawThree-Three.Law-LongSentence {
        max-width: 655px;
    }

    .Locale-ko-NT .LawThree-Three.Law-LongSentence {
        max-width: 670px;
    }

    .Locale-en .LawThree-Three.Law-LongSentence {
        max-width: 580px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .LawThree-Three.Law-LongSentence {
        max-width: 820px;
    }

    .Locale-ko-NT .LawThree-Three.Law-LongSentence {
        max-width: unset;
    }

    .Locale-en .LawThree-Three.Law-LongSentence {
        max-width: 720px;
    }
}