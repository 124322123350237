@media only screen and (min-width: 600px) {
    .Language-Korean .Law-GracePrepWrapup1 {
        max-width: 622px;
    }
    
    .Language-English .Law-GracePrepWrapup1 {
        max-width: 553px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-GracePrepWrapup1 {
        max-width: 813px;
    }
    
    .Language-English .Law-GracePrepWrapup1 {
        max-width: 771px;
    }
}