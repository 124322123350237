.FourLawsMain-PageText {
    z-index: 1051;
}

.Main-WaterColorIcon {
    font-size: 375px;
    width: 375px;
    height: 375px;
    z-index: 1050;
    position: absolute;
    left: -50px;
    top: 0;
    transform: translate(0, calc(50vh - 50% - 101px));
}

.Main-WaterColorIcon.Main-WaterColorIcon-Top {
    transition: all 400ms ease-out;
}

@media only screen and (min-width: 600px) {
    .Main-WaterColorIcon {
        font-size: 300px;
        width: 300px;
        height: 300px;
        left: 0;
        top: -12px;
        /* 45px: PageContainer Padding, 165px: half of MainTitle MaxWidth, 50px: px shift */
        transform: translate(calc(50vw - 50% - 45px - 165px + 50px), calc(50vh - 50% - 101px));
    }

    .Main-WaterColorIcon.Main-WaterColorIcon-Top {
        /** X-axis Calculation
         * 50vw - 50% - 45px => center calculation (45px: PageContainer Padding)
         * 223px: half of MainWrapup MaxWidth
         * 90px: left shift
         * 
         * Y-axis Calculation
         * Center and additional bottom-shift (24px)
         **/
         transform: translate(calc(50vw - 50% - 45px + 223px - 90px), calc(50vh - 50% - 101px + 24px));
    }
}

@media only screen and (min-width: 960px) {
    .Main-WaterColorIcon {
        left: 0;
        top: 0;
        transform: translate(0, calc(50vh - 50% - 101px));
    }

    .Main-WaterColorIcon.Main-WaterColorIcon-Top {
        /** X-axis Calculation
         * PageContainer MaxWidth: 800px
         * PageContainer Left,Right Padding: 45px each
         * WaterColorIcon size / 2: 150px
         * left shift: 208px
         * Result: 800px - (45px * 2) - 150px - 208px = 410px
         * 
         * Y-axis Calculation
         * Center and additional bottom-shift (12px)
         **/
        transform: translate(352px, calc(50vh - 50% - 101px + 12px));
    }
}

@media only screen and (min-width: 1280px) {
    .Main-WaterColorIcon {
        font-size: 375px;
        width: 375px;
        height: 375px;
        left: -50px;
        top: 0;
    }

    .Main-WaterColorIcon.Main-WaterColorIcon-Top {
        /** X-axis Calculation
         * PageContainer MaxWidth: 900px
         * PageContainer Left,Right Padding: 24px each
         * WaterColorIcon size: 375px
         * Result: 900px - (24px * 2) - 375px = 527px
         * 
         * Y-axis Calculation
         * Center and additional bottom-shift (12px)
         **/
        transform: translate(527px, calc(50vh - 50% - 101px + 12px));
    }
}

