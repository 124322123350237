.DrawerMenu-IconButton.MuiIconButton-root {
    color: black;
}

.DrawerMenu-IconButton.MuiIconButton-root img {
    width: 28px;
    height: 28px;
}

.DrawerMenu-List .MuiListSubheader-root, .DrawerMenu-List .MuiTypography-root { 
    font-size: 18px;
}

.DrawerMenu-List .MuiListSubheader-root a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.54);
}

.copyright {
    margin-top: auto;
    text-align: right;
    padding: 10px;
    font-weight: bold;
}