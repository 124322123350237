@media only screen and (min-width: 960px) {
    .Locale-ko-RR .Law-Suggest3-John1421 {
        max-width: 505px;
    }

    .Locale-ko-NT .Law-Suggest3-John1421,
    .Locale-en .Law-Suggest3-John1421 {
        max-width: 595px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .Law-Suggest3-John1421 {
        max-width: 630px;
    }

    .Locale-ko-NT .Law-Suggest3-John1421,
    .Locale-en .Law-Suggest3-John1421 {
        max-width: 745px;
    }
}