/* .PageTitle.LawOne-PageTitle .PageTitle-Wrapper {
    max-width: 195px;
} */

@media only screen and (min-width: 600px) {
    .PageTitle.LawOne-PageTitle .PageTitle-Wrapper {
        max-width: 425px;
    }

    .PageTitle.LawOne-PageTitle .PageTitle-IconBox {
        transform: translateX(calc(425px / 2 - 50%));
    }

    .PageTitle.LawOne-PageTitle.PageTitle-Minimized {
        transform: scale(0.6) translate(calc((-425px / 2) / 0.6 + 120px), 80px);
    }
}

@media only screen and (min-width: 960px) {
    .Language-Korean .PageTitle.LawOne-PageTitle .PageTitle-Wrapper {
        max-width: 520px;
    }

    .Language-English .PageTitle.LawOne-PageTitle .PageTitle-Wrapper {
        max-width: 470px;
    }

    .PageTitle.LawOne-PageTitle .PageTitle-IconBox {
        transform: none;
    }

    .PageTitle.LawOne-PageTitle.PageTitle-Minimized {
        transform: scale(0.6) translate(calc(-30% + 25px), -48px);
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .PageTitle.LawOne-PageTitle .PageTitle-Wrapper {
        max-width: 650px;
    }

    .Language-English .PageTitle.LawOne-PageTitle .PageTitle-Wrapper {
        max-width: 580px;
    }
}