@media only screen and (min-width: 960px) {
    .Language-Korean .Law-GraceWrapup1 {
        max-width: 635px;
    }

    .Language-English .Law-GraceWrapup1 {
        max-width: 560px;
    }

    .Locale-ko-RR .Law-GraceWrapup1-IThess518 {
        max-width: 455px;
    }

    .Locale-ko-NT .Law-GraceWrapup1-IThess518 {
        max-width: 550px;
    }

    .Locale-en .Law-GraceWrapup1-IThess518 {
        max-width: 440px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-GraceWrapup1 {
        max-width: 765px;
    }

    .Language-English .Law-GraceWrapup1 {
        max-width: 700px;
    }

    .Locale-ko-RR .Law-GraceWrapup1-IThess518 {
        max-width: 565px;
    }

    .Locale-ko-NT .Law-GraceWrapup1-IThess518 {
        max-width: 660px;
    }

    .Locale-en .Law-GraceWrapup1-IThess518 {
        max-width: 550px;
    }
}