/* Grace3 : Answer 1 */
.Language-Korean .Answer-Grace3-Q1 {
    max-width: 217px;
}

.Language-English .Answer-Grace3-Q1 {
    max-width: 205px;
}

/* Grace3 : Answer 2 */
.Language-Korean .Answer-Grace3-Q2 {
    max-width: 306px;
}

.Language-English .Answer-Grace3-Q2 {
    max-width: 261px;
}

@media only screen and (min-width: 960px) {
    /* Grace3 : (John 1:12) */
    .Locale-ko-RR .Law-Grace3-John112, 
    .Locale-en .Law-Grace3-John112 {
        max-width: 425px;
    }

    .Locale-ko-NT .Law-Grace3-John112 {
        max-width: 550px;
    }

    /* Grace3 : Question 2 */
    .Language-Korean .Law-Grace3-Q2 {
        max-width: 512px;
    }

    .Language-English .Law-Grace3-Q2 {
        max-width: 400px;
    }
}

@media only screen and (min-width: 1280px) {
    /* Grace3 : (John 1:12) */
    .Locale-ko-RR .Law-Grace3-John112, 
    .Locale-en .Law-Grace3-John112 {
        max-width: 530px;
    }

    .Locale-ko-NT .Law-Grace3-John112 {
        max-width: 685px;
    }

    /* Grace3 : Question 2 */
    .Language-Korean .Law-Grace3-Q2 {
        max-width: 640px;
    }

    .Language-English .Law-Grace3-Q2 {
        max-width: 500px;
    }
}