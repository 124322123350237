.PageTitle.LawFour-PageTitle .PageTitle-Wrapper {
    max-width: 245px;
} 

.LawFour-Greet-Title {
    text-align: center;
}

.LawFour-Greet-Description {
    text-align: center;
}

.LawFour-Greet-flex {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0px -13px;
}

.LawFour-Greet-Description-flex {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.LawFour-Greet-Content {
    display: flex;
    margin-left: 30px;
    flex-direction: column;
}

.LawFour-Greet-Row {
    display: flex;
}

.LawFour-Greet-Row.LawFour-Greet-Prefix {
    width: 50px;
}

.LawFour-Greet-Sentence {
    margin-left: 8px;
    text-align: left;
}

.Language-English .LawFour-Greet-Row > .Prefix-Span {
    margin-left: 6px;
    margin-right: 18.5px;
}

.Language-Korean .LawFour-Greet-Row > .Prefix-Span {
    margin-left: 3px;
    margin-right: 8px;
}

.LawFour-Greet-flex .DiagramLink {
    padding: 13px;
    margin: 0 20px;
}

.LawFour-Greet-Description-flex img:not(.LawFour-Icon-Inline) {
    font-size: inherit;
    width: inherit;
    height: inherit;
}

.LawFour-Greet-flex img:not(.LawFour-Icon-Inline), 
.LawFour-Greet-Description-flex .LawFour-Greet-DiagramBox {
    font-size: 200px;
    width: 200px;
    height: 200px;
}

img.LawFour-Icon-Inline {
    font-size: 30px;
    width: 30px;
    height: 55px;
    margin-right: 7px;
}

img.LawFour-Icon-Inline.LawFour-Icon-Cross {
    width: auto;
    height: 30px;
    margin: auto 12px auto 5px;
}

.LawFour-SubTitle-MarginLeft {
    margin-left: unset;
}

@media only screen and (min-width: 600px) {
    .PageTitle.LawFour-PageTitle .PageTitle-Wrapper {
        max-width: 514px;
    }

    .LawFour-Greet-flex {
        flex-direction: row;
        justify-content: center;
    }

    .LawFour-Greet-Content {
        max-width: 459px;
        margin-top: 30px;
    }

    img.LawFour-Icon-Inline:not(.LawFour-Icon-Cross) {
        height: 42px;
    }

    .Language-Korean .LawFour-Greet-Row > .Prefix-Span {
        margin-right: 13px;
    }
}

@media only screen and (min-width: 960px) {
    .PageTitle.LawFour-PageTitle .PageTitle-Wrapper {
        max-width: 625px;
    }

    .LawFour-Greet-Description-flex {
        flex-direction: row;
        justify-content: center;
    }

    .LawFour-Greet-Description {
        text-align: unset;
    }

    .LawFour-Greet-Content {
        max-width: unset;
        margin-top: 0;
        margin-left: 30px;
    }
    
    .LawFour-SubTitle-MarginLeft {
        margin-left: 234px;
    }

    .LawFour-Greet-flex .DiagramLink {
        margin: 0 30px;
    }
}

@media only screen and (min-width: 1280px) {
    .PageTitle.LawFour-PageTitle .PageTitle-Wrapper {
        max-width: 775px;
    }

    .LawFour-Greet-flex img:not(.LawFour-Icon-Inline), 
    .LawFour-Greet-Description-flex .LawFour-Greet-DiagramBox {
        font-size: 250px;
        width: 250px;
        height: 250px;
    }

    .LawFour-SubTitle-MarginLeft {
        margin-left: 283px;
    }

    .LawFour-Greet-flex .DiagramLink {
        padding: 25px;
        margin: 0 30px;
    }

    img.LawFour-Icon-Inline:not(.LawFour-Icon-Cross) {
        height: 55px;
    }

    .LawFour-Greet-Row.LawFour-Greet-Prefix {
        width: 54px;
    }

    .Language-English .LawFour-Greet-Row > .Prefix-Span {
        margin-left: 6px;
        margin-right: 16px;
    }

    .Language-Korean .LawFour-Greet-Row > .Prefix-Span {
        margin-right: 8px;
    }
}