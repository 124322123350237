@media only screen and (min-width: 600px) {
    .Language-English .Law-Prayer3 {
        max-width: 438px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-English .Law-Prayer3 {
        max-width: none;
    }
}