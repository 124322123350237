@media only screen and (min-width: 960px) {
    .Locale-ko-RR .LawThree-One.Law-LongSentence {
        max-width: 530px;
    }

    .Locale-ko-NT .LawThree-One.Law-LongSentence {
        max-width: 610px;
    }

    .Locale-en .LawThree-One.Law-LongSentence {
        max-width: 600px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .LawThree-One.Law-LongSentence {
        max-width: 650px;
    }

    .Locale-ko-NT .LawThree-One.Law-LongSentence {
        max-width: 770px;
    }

    .Locale-en .LawThree-One.Law-LongSentence {
        max-width: 745px;
    }
}