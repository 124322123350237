.FourLawsMain-Title {
    margin: auto;
    text-align: center;
    font-weight: bold;
}

.FourLawsMain-Kanji {
    font-family: 'sans-serif';
}

@media only screen and (min-width: 600px) {
    .FourLawsMain-Title {
        max-width: 294px;
    }
}

@media only screen and (min-width: 960px) {
    .FourLawsMain-Title {
        max-width: none;
    }
}