.LawFour-Three {
    max-width: 480px;
    text-align: center;
}

@media only screen and (min-width: 600px) {
    .LawFour-Three {
        max-width: 550px;
    }
}

@media only screen and (min-width: 1280px) {
    .LawFour-Three {
        max-width: 700px;
    }
}