@font-face {
    font-family: 'Ikkeullim';
    src: local('aIkkeullimM'),
         url('./assets/fonts/aIkkeullimM.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ikkeullim';
    src: local('aIkkeullimB'),
         url('./assets/fonts/aIkkeullimB.ttf');
    font-weight: bold;
}

body {
    margin: 0;
    font-family: "Ikkeullim", "Helvetica", "Arial", "sans-serif";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    word-break: keep-all;
}