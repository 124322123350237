@media only screen and (min-width: 960px) {
    .Locale-ko-RR .LawOne-One.Law-LongSentence {
        max-width: 570px;
    }
    
    .Locale-ko-NT .LawOne-One.Law-LongSentence {
        max-width: 615px;
    }
    
    .Locale-en .LawOne-One.Law-LongSentence {
        max-width: 505px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .LawOne-One.Law-LongSentence {
        max-width: 715px;
    }

    .Locale-ko-NT .LawOne-One.Law-LongSentence {
        max-width: 770px;
    }
    
    .Locale-en .LawOne-One.Law-LongSentence {
        max-width: 630px;
    }
}