.AppStepper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AppStepper .AppStepper-Content {
    padding: 0px 24px;
}

.AppStepper-Button {
    display: flex;
    align-items: center;
    outline: 0;
    border: none;
    background: none;
    font: inherit;
    position: absolute;
    bottom: 30px;
    color: black;
    cursor: pointer;
}

.AppStepper-Arrow {
    width: 1.5rem;
    height: 1.5rem;
}

.AppStepper-Button.AppStepper-Previous {
    left: 30px;
}

.AppStepper-Button.AppStepper-Next {
    right: 30px
}

.AppStepper-Button.AppStepper-Previous .AppStepper-Arrow {
    transform: rotate(180deg);
    margin-right: 10px;
}

.AppStepper-Button.AppStepper-Next .AppStepper-Arrow {
    margin-left: 10px;
}

@media only screen and (min-width: 1280px) {
    .AppStepper .AppStepper-Content {
        max-width: 819px;
    }
}