@media only screen and (min-width: 960px) {
    .Locale-ko-RR .LawFour-Four.Law-LongSentence {
        max-width: 690px;
    }

    .Locale-ko-NT .LawFour-Four.Law-LongSentence {
        max-width: 565px;
    }

    .Locale-en .LawFour-Four.Law-LongSentence {
        max-width: 645px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .LawFour-Four.Law-LongSentence {
        max-width: 820px;
    }

    .Locale-ko-NT .LawFour-Four.Law-LongSentence {
        max-width: 700px;
    }

    .Locale-en .LawFour-Four.Law-LongSentence {
        max-width: 810px;
    }
}