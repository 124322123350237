@media only screen and (min-width: 960px) {
    .Locale-ko-RR .Law-Grace4-John524 {
        max-width: 512px;
    }

    .Locale-ko-NT .Law-Grace4-John524 {
        max-width: 565px;
    }

    .Locale-en .Law-Grace4-John524 {
        max-width: 600px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .Law-Grace4-John524 {
        max-width: 640px;
    }

    .Locale-ko-NT .Law-Grace4-John524 {
        max-width: 710px;
    }

    .Locale-en .Law-Grace4-John524 {
        max-width: 750px;
    }
}