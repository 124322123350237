.Law-PageContent {
    margin: auto;
}

.Law-PageContent .Law-SubTitle {
    margin-bottom: 30px;
    display: inline-block;
    border-bottom: 3px solid black;
    line-height: 40px;
}

.Law-PageContent .Law-Sentence-Margin {
    margin-bottom: 30px;
}

.Law-PageContent .Law-Sentence-Bold,
.PageTitle.Law-PageTitle .PageText-Content {
    font-weight: bold;
}

.Law-PageContent .Law-Sentence-Center {
    text-align: center;
}

.PageText-Content.Law-LongSentence {
    max-width: unset;
    text-align: justify;
}

.Law-Question {
    display: flex;
    flex-direction: column;
}

.Law-Question-Below-words {
    max-width: 591px;
    margin: 0 auto;
    margin-top: 50px;
    font-weight: bold;
}

.Law-Question-Below-words.No-maxwidth {
    max-width: none;
}

.Law-Question-Answer {
    display: flex;
    justify-content: space-around;
    margin: 100px 0 0 0;
}

.Law-Question-Answer-Single {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.Law-Question-Answer-Single.Below-words {
    margin-top: 50px;
}

.Law-Question-Answer .BottomNavigation, .Law-Question-Answer-Single, .Law-Button {
    display: flex;
    align-items: center;
    padding: 5px 25px;
    box-shadow: 0 15px 5px -10px #fee0ce;
    max-width: fit-content;
}

.Law-Question-Answer-Single, 
.Law-Button,
.Law-Title-Animated .Law-Title-Animated-line2 {
    margin-left: auto;
    margin-right: auto;
}

.Law-Button {
    outline: 0;
    border: none;
    background: none;
    font: inherit;
    cursor: pointer;
}

.Law-Question-Answer .PageText, .Law-Question-Answer-Single .PageText, .Law-Button .PageText {
    line-height: 45px;
}

.Law-Question-Answer .BottomNavigation {
    position: relative;
    left: 0;
    bottom: 0;
}

.Law-Text-Highlight {
    background-color: #fee0ce;
}

/* animated title (Grace, Suggest) */
.Law-Title-Animated.PageText {
    position: absolute;
    left: 0;
    top: 0;
    /* 50% viewport height - 50% own height - PageContainer padding (101px) */
    transform: translateY(calc(50vh - 50% - 101px));
    width: 100%;
    z-index: -1;
}

.Law-Title-Animated.Law-Title-Animated-content {
    transition: all 400ms ease-out;
    /* y: - (PageContainer Padding (101px) - Header Height (89px)) */
    transform: scale(0.6) translateY(calc(-12px / 0.6));
}

.Law-Title-Animated .Law-Title-Animated-line2 {
    opacity: 1;
    text-align: justify;
}

.Law-Title-Animated.Law-Title-Animated-content .Law-Title-Animated-line2 {
    opacity: 0;
}

/* bible words dialog title (animated) */
.Law-Dialog-Title {
    position: absolute;
    left: 0;
    top: 20px;
    /* 
     * Dialog w, h = 90w, 90h 
     * x: half viewport width - half own width
     * y: half viewport height - half own height - 20px
     */
    transform: translate(calc(45vw - 50%), calc(45vh - 50% - 20px));
    max-width: 771px;
} 

.Law-Dialog-Title.Law-Dialog-Title-Minimized {
    transition: all 400ms ease-out;
    transform: scale(0.6) translate(calc((45vw - 50%) / 0.6), calc(-40% + 20px));
}

@media only screen and (min-width: 600px) {
    .PageText-Content.Law-LongSentence {
        max-width: 703px;
    }

    .PageTitle.Law-PageTitle .PageText-Content {
        text-align: center;
    }
}

@media only screen and (min-width: 960px) {
    .PageTitle.Law-PageTitle .PageText-Content {
        text-align: justify;
    }

    .Law-Dialog-Title {
        transform: translate(calc(42.5vw - 50%), calc(42.5vh - 50% - 20px));
    } 
    
    .Law-Dialog-Title.Law-Dialog-Title-Minimized {
        transform: scale(0.6) translate(calc((42.5vw - 50%) / 0.6), calc(-40% + 20px));
    }
}

@media only screen and (min-width: 1280px) {
    .PageText-Content.Law-LongSentence {
        width: unset;
        max-width: unset;
    }
}