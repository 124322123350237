.TranslationMenu-Button.MuiButton-root {
    color: black;
}

.TranslationMenu-Button.MuiButton-root span {
    font-size: 18px;
    text-transform: none;
}

.TranslationMenu-Button.MuiButton-root svg {
    width: 28px;
    height: 28px;
}

.TranslationMenu-Text {
    padding: 0 10px;
}

.TranslationMenu-Menu .MuiList-padding {
    padding: 0;
}

.TranslationMenu-Menu .MuiListItem-root {
    font-size: 18px;
}