@media only screen and (min-width: 960px) {
    .Locale-ko-RR .LawOne-Two.Law-LongSentence {
        max-width: 600px;
    }

    .Locale-ko-NT .LawOne-Two.Law-LongSentence {
        max-width: 585px;
    }

    .Locale-en .LawOne-Two.Law-LongSentence {
        max-width: 620px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .LawOne-Two.Law-LongSentence {
        max-width: 765px;
    }

    .Locale-ko-NT .LawOne-Two.Law-LongSentence {
        max-width: 730px;
    }

    .Locale-en .LawOne-Two.Law-LongSentence {
        max-width: 775px;
    }
}