@media only screen and (min-width: 600px) {
    .LawFour-Wrapup-Prep {
        max-width: 450px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-Korean .LawFour-Wrapup-Prep {
        max-width: 675px;
    }

    .Language-English .LawFour-Wrapup-Prep {
        max-width: 565px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .LawFour-Wrapup-Prep {
        max-width: 840px;
    }

    .Language-English .LawFour-Wrapup-Prep {
        max-width: 710px;
    }
}