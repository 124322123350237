.Language-English .Answer.Answer-Confirm-first-question6, 
.Language-Korean .Answer.Answer-Confirm-first-question6 {
    margin-top: 100px;
}

.Language-English .Answer.Answer-Confirm-first-question6 {
    max-width: 255px;
}

.Language-Korean .Answer.Answer-Confirm-first-question6 {
    max-width: 100px;
}

@media only screen and (min-width: 600px) {
    .Language-Korean .Law-Confirm1-Question6 {
        max-width: 448px;
    }
    
    .Language-English .Law-Confirm1-Question6 {
        max-width: 452px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-Confirm1-Question6 {
        max-width: 561px;
    }

    .Language-English .Law-Confirm1-Question6 {
        max-width: 565px;
    }
}


