@media only screen and (min-width: 960px) {
    .Locale-ko-RR .Law-Suggest2-Acts1711 {
        max-width: 525px;
    }

    .Locale-ko-NT .Law-Suggest2-Acts1711 {
        max-width: 550px;
    }

    .Locale-en .Law-Suggest2-Acts1711 {
        max-width: 485px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .Law-Suggest2-Acts1711 {
        max-width: 655px;
    }

    .Locale-ko-NT .Law-Suggest2-Acts1711 {
        max-width: 690px;
    }
    
    .Locale-en .Law-Suggest2-Acts1711 {
        max-width: 610px;
    }
}