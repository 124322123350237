@media only screen and (min-width: 960px) {
  .Language-English .Law-GraceWrapup2 {
      max-width: 335px;
  }
}

@media only screen and (min-width: 1280px) {
  .Language-English .Law-GraceWrapup2 {
      max-width: 415px;
  }
}