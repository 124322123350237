@media only screen and (min-width: 960px) {
    .Law-Suggest6-Title {
        max-width: 470px;
    }

    /* Suggest6 : (Galatians 5:16,17) */
    .Locale-ko-RR .Law-Suggest6-Gal51617,
    .Locale-ko-NT .Law-Suggest6-Gal51617 {
        max-width: 571px;
    }

    .Locale-en .Law-Suggest6-Gal51617 {
        max-width: 535px;
    }

    /* Suggest6 : (Acts 1:8) */
    .Locale-ko-RR .Law-Suggest6-Acts18 {
        max-width: 450px;
    }

    .Locale-ko-NT .Law-Suggest6-Acts18 {
        max-width: 485px;
    }

    .Locale-en .Law-Suggest6-Acts18 {
        max-width: 515px;
    }
}

@media only screen and (min-width: 1280px) {
    .Law-Suggest6-Title {
        max-width: 585px;
    }

    /* Suggest6 : (Galatians 5:16,17) */
    .Locale-ko-RR .Law-Suggest6-Gal51617 {
        max-width: 713px;
    }

    .Locale-ko-NT .Law-Suggest6-Gal51617 {
        max-width: 714px;
    }

    .Locale-en .Law-Suggest6-Gal51617 {
        max-width: 670px;
    }

    /* Suggest6 : (Acts 1:8) */
    .Locale-ko-RR .Law-Suggest6-Acts18 {
        max-width: 560px;
    }
    
    .Locale-ko-NT .Law-Suggest6-Acts18 {
        max-width: 605px;
    }

    .Locale-en .Law-Suggest6-Acts18 {
        max-width: 645px;
    }
}