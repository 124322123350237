@media only screen and (min-width: 960px) {
    .Language-Korean .LawFour-Five.Law-LongSentence {
        max-width: 680px;
    }

    .Language-English .LawFour-Five.Law-LongSentence {
        max-width: 695px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .LawFour-Five.Law-LongSentence {
        max-width: 850px;
    }

    .Language-English .LawFour-Five.Law-LongSentence {
        max-width: 770px;
    }
}