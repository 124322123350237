.LawTwo-Four-flex {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.LawTwo-Four-flex .LawTwo-Four-DiagramBox {
    font-size: 300px;
    width: 300px;
    height: 300px;
    margin-bottom: 30px;
}

.LawTwo-Four-flex .LawTwo-Four-DiagramBox > img {
    font-size: inherit;
    width: inherit;
    height: inherit;
}

@media only screen and (min-width: 600px) {
    .LawTwo-Four-Text {
        max-width: 465px;
    }
}

@media only screen and (min-width: 960px) {
    .LawTwo-Four-flex {
        flex-direction: row;
    }

    .LawTwo-Four-Text {
        max-width: none;
    }

    .LawTwo-Four-flex .LawTwo-Four-DiagramBox {
        margin-bottom: 0;
        margin-right: 30px;
    }

    .Language-Korean .LawTwo-Four-flex .LawTwo-Four-DiagramBox {
        font-size: 260px;
        width: 260px;
        height: 260px;
    }

    .Language-English .LawTwo-Four-flex .LawTwo-Four-DiagramBox {
        font-size: 300px;
        width: 300px;
        height: 300px;
    }

    .Language-Korean .LawTwo-Four.Law-LongSentence {
        max-width: 660px;
    }

    .Language-English .LawTwo-Four.Law-LongSentence {
        max-width: 710px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .LawTwo-Four-flex .LawTwo-Four-DiagramBox,
    .Language-English .LawTwo-Four-flex .LawTwo-Four-DiagramBox {
        font-size: 340px;
        width: 340px;
        height: 340px;
    }

    .Language-Korean .LawTwo-Four.Law-LongSentence,
    .Language-English .LawTwo-Four.Law-LongSentence {
        max-width: 845px;
    }
}