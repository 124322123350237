@media only screen and (min-width: 600px) {
    .Language-English .Law-ConfirmFirst1-Question, 
    .Language-Korean .Law-ConfirmFirst1-Question {
        max-width: 507px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-Korean .Law-ConfirmFirst1-Question {
        max-width: 670px;
    }

    .Language-English .Law-ConfirmFirst1-Question {
        max-width: 525px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-ConfirmFirst1-Question {
        max-width: 840px;
    }

    .Language-English .Law-ConfirmFirst1-Question {
        max-width: 650px;
    }
}