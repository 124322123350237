.Law-Feelings {
    overflow: hidden;
    text-align: center;
}

.Train-DiagramBox {
    width: 450px;
    height: 175.5px;
    margin: 2.5rem auto 0 auto;
}

.Train-DiagramBox > img {
    width: inherit;
    height: inherit;
}

@media only screen and (min-width: 960px) {
    /* Korean */
    .Language-Korean .Law-Feelings > .Law-Feelings-One {
        max-width: 630px;
    }

    .Language-Korean .Law-Feelings > .Law-Feelings-Two {
        max-width: 575px;
    }

    .Language-Korean .Law-Feelings > .Law-Feelings-Three {
        max-width: 615px;
    }

    /* English */
    .Language-English .Law-Feelings > .Law-Feelings-One {
        max-width: 510px;
    }

    .Language-English .Law-Feelings > .Law-Feelings-Two {
        max-width: 620px;
    }

    .Language-English .Law-Feelings > .Law-Feelings-Three {
        max-width: 615px;
    }
}

@media only screen and (min-width: 1280px) {
    .Train-DiagramBox {
        width: 550px;
        height: 213.5px;
    }

    /* Korean */
    .Language-Korean .Law-Feelings > .Law-Feelings-One {
        max-width: 790px;
    }

    .Language-Korean .Law-Feelings > .Law-Feelings-Two {
        max-width: 720px;
    }

    .Language-Korean .Law-Feelings > .Law-Feelings-Three {
        max-width: 770px;
    }

    /* English */
    .Language-English .Law-Feelings > .Law-Feelings-One {
        max-width: 635px;
    }

    .Language-English .Law-Feelings > .Law-Feelings-Two {
        max-width: 775px;
    }

    .Language-English .Law-Feelings > .Law-Feelings-Three {
        max-width: 770px;
    }
}