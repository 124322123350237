@media only screen and (min-width: 960px) {
    .Language-Korean .LawOne-Wrapup {
        max-width: 390px;
    }

    .Language-English .LawOne-Wrapup {
        max-width: 485px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .LawOne-Wrapup {
        max-width: 485px;
    }

    .Language-English .LawOne-Wrapup {
        max-width: 605px;
    }
}