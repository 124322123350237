.Header.MuiAppBar-colorPrimary {
    background-color: white;
    color: black;
    box-shadow: none;
}

.Header .MuiToolbar-root {
    padding: 12px 0 0 0;
}

.Header .MuiToolbar-root > *:not(:first-child) {
    margin-left: 24px;
}

.Header .Header-Logo {
    height: 48px;
    padding: 12px;
}

.Header .spacing {
    flex: 1;
}