.Law-ConfirmThird-Words {
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 960px) {
    .Locale-ko-RR .Law-LongSentence > .Law-ConfirmThird-Words {
        max-width: 490px;
    }

    .Locale-ko-NT .Law-LongSentence > .Law-ConfirmThird-Words {
        max-width: 465px;
    }

    .Locale-en .Law-LongSentence > .Law-ConfirmThird-Words {
        max-width: 470px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .Law-LongSentence > .Law-ConfirmThird-Words {
        max-width: 615px;
    }

    .Locale-ko-NT .Law-LongSentence > .Law-ConfirmThird-Words {
        max-width: 580px;
    }

    .Locale-en .Law-LongSentence > .Law-ConfirmThird-Words {
        max-width: 585px;
    }
}