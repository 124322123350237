@media only screen and (min-width: 960px) {
    .Locale-ko-RR .Law-Suggest5-IPet57 {
        max-width: 290px;
    }

    .Locale-ko-NT .Law-Suggest5-IPet57 {
        max-width: 430px;
    }

    .Locale-en .Law-Suggest5-IPet57 {
        max-width: 330px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .Law-Suggest5-IPet57 {
        max-width: 360px;
    }

    .Locale-ko-NT .Law-Suggest5-IPet57 {
        max-width: 535px;
    }
    
    .Locale-en .Law-Suggest5-IPet57 {
        max-width: 415px;
    }
}