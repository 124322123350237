@media only screen and (min-width: 960px) {
    .Language-Korean .Suggest-Title-line2 {
        max-width: 650px;
    }

    .Language-English .Suggest-Title-line2 {
        max-width: 565px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Suggest-Title-line2 {
        max-width: 815px;
    }

    .Language-English .Suggest-Title-line2 {
        max-width: 710px;
    }
}