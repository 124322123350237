@media only screen and (min-width: 960px) {
    .Language-Korean .ConfirmFirst-Two {
        max-width: 615px;
    }

    .Language-English .ConfirmFirst-Two {
        max-width: 685px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .ConfirmFirst-Two {
        max-width: 765px;
    }

    .Language-English .ConfirmFirst-Two {
        max-width: 860px;
    }
}