@media only screen and (min-width: 960px) {
    .Locale-ko-RR .Confirm2-QWords {
        max-width: 625px;
    }

    .Locale-ko-NT .Confirm2-QWords {
        max-width: 660px;
    }

    .Locale-en .Confirm2-QWords {
        max-width: 640px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .Confirm2-QWords {
        max-width: 780px;
    }

    .Locale-ko-NT .Confirm2-QWords {
        max-width: 825px;
    }

    .Locale-en .Confirm2-QWords {
        max-width: 800px;
    }
}