@media only screen and (min-width: 960px) {
    .Locale-ko-RR .LawThree-Two.Law-LongSentence {
        max-width: 600px;
    }

    .Locale-ko-NT .LawThree-Two.Law-LongSentence {
        max-width: 640px;
    }

    .Locale-en .LawThree-Two.Law-LongSentence {
        max-width: 690px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .LawThree-Two.Law-LongSentence {
        max-width: 740px;
    }

    .Locale-ko-NT .LawThree-Two.Law-LongSentence {
        max-width: 800px;
    }

    .Locale-en .LawThree-Two.Law-LongSentence {
        max-width: 840px;
    }
}