@media only screen and (min-width: 960px) {
    .Language-Korean .Law-Wrapup1 {
        max-width: 645px;
    }

    .Language-English .Law-Wrapup1 {
        max-width: 625px;
    }

    .Locale-ko-NT .Law-Wrapup1-Heb1025 {
        max-width: 620px;
    }

    .Locale-ko-RR .Law-Wrapup1-Heb1025,
    .Locale-en .Law-Wrapup1-Heb1025 {
        max-width: 535px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-Wrapup1 {
        max-width: 810px;
    }

    .Language-English .Law-Wrapup1 {
        max-width: 780px;
    }

    .Locale-ko-NT .Law-Wrapup1-Heb1025 {
        max-width: 775px;
    }
    .Locale-ko-RR .Law-Wrapup1-Heb1025,
    .Locale-en .Law-Wrapup1-Heb1025 {
        max-width: 665px;
    }
}