.Law-ConfirmSecond-1-Title {
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 600px) {
    .Language-Korean .Law-ConfirmSecond-1-Title {
        max-width: 466px;
    }

    .Language-English .Law-ConfirmSecond-1-Title {
        max-width: 380px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-English .Law-ConfirmSecond-1-Title {
        max-width: unset;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-ConfirmSecond-1-Title {
        max-width: 617px;
    }
}