.PageTitle {
    position: fixed;
    transition: none;
    left: 0;
    top: 131px;
    background-color: transparent;
    transform: translate(calc(50vw - 50% - 16px), calc(50vh - 50% - 131px)) scale(1);
}

.PageTitle.PageTitle-Minimized {
    display: none; /* min-height 768px will be display:block */
    transition: all 400ms ease-out;
    transform: scale(0.6) translate(0, 0);
    opacity: 1;
}

.PageTitle .PageTitle-Wrapper {
    display: flex;
    align-items: center;
}

.PageTitle .PageTitle-Icon {
    width: inherit;
    height: inherit;
    font-size: inherit;
}

.PageTitle-IconBox {
    font-size: 170px;
    width: 170px;
    height: 170px;
    margin-right: 12px;
}

.PageTitle.PageTitle-Minimized .PageTitle-PageText {
    opacity: 0;
}

@media only screen and (min-width: 600px) {
    .PageTitle {
        transform: translate(calc(50vw - 50%), calc(50vh - 50% - 131px)) scale(1);
        justify-content: start;
    }

    .PageTitle.PageTitle-Minimized {
        transform: scale(0.6) translate(calc((-499px / 2) / 0.6 + 120px), 80px);
        opacity: 0.6;
    }

    .PageTitle .PageTitle-Wrapper {
        max-width: 499px;
    }

    .PageTitle-IconBox {
        position: absolute;
        top: -160px;
        width: 150px;
        height: 150px;
        font-size: 150px;
        transform: translateX(calc(499px / 2 - 50%));
    }
}

@media only screen and (min-width: 960px) {
    .PageTitle.PageTitle-Minimized {
        transform: scale(0.6) translate(calc(-30% + 25px), -48px);
        opacity: 1;
    }

    .PageTitle .PageTitle-Wrapper {
        max-width: none;
    }

    .PageTitle-IconBox {
        position: unset;
        top: unset;
        transform: none;
    }

    .PageTitle.PageTitle-Minimized .PageTitle-PageText {
        height: 0;
    }
}

@media only screen and (min-width: 1280px) {
    .PageTitle {
        left: 0;
    }

    .PageTitle-IconBox {
        font-size: 200px;
        width: 200px;
        height: 200px;
        margin-right: 0;
    }
}

@media only screen and (min-height: 768px) {
    .PageTitle.PageTitle-Minimized {
        display: block;
    }
}