@media only screen and (min-width: 960px) {
    .Language-Korean .Prayer-Four {
        max-width: 675px;
    }

    .Language-English .Prayer-Four {
        max-width: 600px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Prayer-Four {
        max-width: 840px;
    }

    .Language-English .Prayer-Four {
        max-width: 750px;
    }
}