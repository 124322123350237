.Answer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 50px;
    padding: 5px 10px;
    box-shadow: 0 15px 5px -10px #fee0ce;
    height: 45px;
    line-height: 45px;
    margin: 0 auto;
    margin-top: 50px;
}

.Answer-span {
    opacity: 0;
}

.Answer-span.Answer-span-show {
    opacity: 1;
    transition: opacity 750ms ease-out;
}

.Answer-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    outline: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}

.Answer-arrow > img {
    width: 1em;
    height: 1em;
    font-size: 1.5rem;
}

.Answer-arrow.Answer-arrow-left {
    left: -24px;
    transition: left 750ms ease-out;
}
