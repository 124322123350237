@media only screen and (min-width: 960px) {
    .Language-Korean .LawTwo-Two.Law-LongSentence {
        max-width: 660px;
    }
  
    .Language-English .LawTwo-Two.Law-LongSentence {
        max-width: 600px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .LawTwo-Two.Law-LongSentence {
        max-width: 825px;
    }
  
    .Language-English .LawTwo-Two.Law-LongSentence {
        max-width: 755px;
    }
}