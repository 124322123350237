.Language-English .Answer.Answer-Confirm-third-question1 {
    max-width: 55px;
}

.Language-Korean .Answer.Answer-Confirm-third-question1 {
    max-width: 105px;
}

@media only screen and (min-width: 600px) {
    .Language-Korean .Law-ConfirmThird-Q1-Title {
        max-width: 471px;
    }
    
    .Language-English .Law-ConfirmThird-Q1-Title {
        max-width: 404px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-English .Law-ConfirmThird-Q1-Title {
        max-width: unset;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-ConfirmThird-Q1-Title {
        max-width: 588px;
    }
}