@media only screen and (min-width: 600px) {
    .LawFour-Wrapup-Review {
        max-width: 577px;
    }
}

@media only screen and (min-width: 1280px) {
    .LawFour-Wrapup-Review {
        max-width: 722px;
    }
}