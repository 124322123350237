@media only screen and (min-width: 600px) {
    .Language-Korean .Law-Prayer-Review1 {
        max-width: 380px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-Korean .Law-Prayer-Review1 {
        max-width: unset;
    }
}