@media only screen and (min-width: 960px) {
    .Language-Korean .Law-ConfirmThird-2-Title {
        max-width: 445px;
    }

    .Language-English .Law-ConfirmThird-2-Title {
        max-width: 590px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-ConfirmThird-2-Title {
        max-width: 555px;
    }

    .Language-English .Law-ConfirmThird-2-Title {
        max-width: 740px;
    }
}