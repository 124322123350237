@media only screen and (min-width: 960px) {
    .Language-Korean .Law-Confirm1-Question1 {
        max-width: unset;
    }

    .Language-English .Law-Confirm1-Question1 {
        max-width: 455px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-English .Law-Confirm1-Question1 {
        max-width: 590px;
    }
}