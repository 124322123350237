@media only screen and (min-width: 960px) {
    .Language-Korean .LawFour-Greet-Two {
        max-width: 710px;
    }

    .Language-English .LawFour-Greet-Two {
        max-width: 665px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .LawFour-Greet-Two {
        max-width: 870px;
    }

    .Language-English .LawFour-Greet-Two {
        max-width: 815px;
    }
}