@media only screen and (min-width: 600px) {
    .Language-Korean .Law-GracePrepMain2 {
        max-width: 553px;
    }
    
    .Language-English .Law-GracePrepMain2 {
        max-width: 525px;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-Korean .Law-GracePrepMain2 {
        max-width: 691px;
    }
    
    .Language-English .Law-GracePrepMain2 {
        max-width: 656px;
    }
}