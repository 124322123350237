@media only screen and (min-width: 960px) {
    .Locale-ko-RR .LawFour-One.Law-LongSentence {
        max-width: 520px;
    }

    .Locale-ko-NT .LawFour-One.Law-LongSentence {
        max-width: 615px;
    }

    .Locale-en .LawFour-One.Law-LongSentence {
        max-width: 670px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .LawFour-One.Law-LongSentence {
        max-width: 640px;
    }

    .Locale-ko-NT .LawFour-One.Law-LongSentence {
        max-width: 770px;
    }

    .Locale-en .LawFour-One.Law-LongSentence {
        max-width: 790px;
    }
}