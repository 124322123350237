@media only screen and (min-width: 600px) {
    .PageText.LawTwo-Wrapup {
        max-width: 458px;
    }
}

@media only screen and (min-width: 960px) {
    .PageText.LawTwo-Wrapup {
        max-width: none;
    }
}