@media only screen and (min-width: 960px) {
    /* Suggest4 : (Matthew 4:19) */
    .Locale-ko-NT .Law-Suggest4-Matt419 {
        max-width: 490px;
    }

    .Locale-ko-RR .Law-Suggest4-Matt419,
    .Locale-en .Law-Suggest4-Matt419 {
        max-width: 390px;
    }

    /* Suggest4 : (John 15:8) */
    .Locale-ko-RR .Law-Suggest4-John158,
    .Locale-ko-NT .Law-Suggest4-John158 {
        max-width: 460px;
    }
    
    .Locale-en .Law-Suggest4-John158 {
        max-width: 490px;
    }
}

@media only screen and (min-width: 1280px) {
    /* Suggest4 : (Matthew 4:19) */
    .Locale-ko-NT .Law-Suggest4-Matt419 {
        max-width: 615px;
    }
    
    .Locale-ko-RR .Law-Suggest4-Matt419,
    .Locale-en .Law-Suggest4-Matt419 {
        max-width: 485px;
    }

    /* Suggest4 : (John 15:8) */
    .Locale-ko-RR .Law-Suggest4-John158,
    .Locale-ko-NT .Law-Suggest4-John158 {
        max-width: 575px;
    }
    
    .Locale-en .Law-Suggest4-John158 {
        max-width: 600px;
    }
}