@media only screen and (min-width: 960px) {
  .Locale-ko-RR .LawTwo-Three.Law-LongSentence,
  .Locale-ko-NT .LawTwo-Three.Law-LongSentence {
      max-width: 520px;
  }

  .Locale-en .LawTwo-Three.Law-LongSentence {
      max-width: 620px;
  }
}

@media only screen and (min-width: 1280px) {
  .Locale-ko-RR .LawTwo-Three.Law-LongSentence,
  .Locale-ko-NT .LawTwo-Three.Law-LongSentence {
      max-width: 650px;
  }

  .Locale-en .LawTwo-Three.Law-LongSentence {
      max-width: 775px;
  }
}