@media only screen and (min-width: 960px) {
    .Locale-ko-RR .LawFour-Two.Law-LongSentence, 
    .Locale-ko-NT .LawFour-Two.Law-LongSentence {
        max-width: 660px;
    }

    .Locale-en .LawFour-Two.Law-LongSentence {
        max-width: 520px;
    }
}

@media only screen and (min-width: 1280px) {
    .Locale-ko-RR .LawFour-Two.Law-LongSentence, 
    .Locale-ko-NT .LawFour-Two.Law-LongSentence {
        max-width: 825px;
    }

    .Locale-en .LawFour-Two.Law-LongSentence {
        max-width: 640px;
    }
}