@media only screen and (min-width: 600px) {
    .Language-Korean .Law-Prayer-Review2 {
        max-width: 427px;
    }

    .Language-English .Law-Prayer-Review2 {
        max-width: 514px;
    }
}

@media only screen and (min-width: 960px) {
    .Language-Korean .Law-Prayer-Review2 {
        max-width: unset;
    }
}

@media only screen and (min-width: 1280px) {
    .Language-English .Law-Prayer-Review2 {
        max-width: 718px;
    }
}